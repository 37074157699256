import React from "react";

export const FaithChanges = () => (
  <div className="max-w-5xl mx-auto p-5 bg-[#f6f4ef] mt-5 mb-20 rounded-lg shadow-lg">
    <h1 className="text-3xl font-bold mb-4">Faith Changes</h1>
    <p className="text-lg mt-4 text-gray-600">
      Lately I’ve been musing on alot of things. I'm consciously trying to avoid
      the words 'struggling with’ because these things make me realize areas I
      need to grow in. One big thing on my mind is staying true and grounded to
      my faith. As I grow older, I’ve realized that I had been taking a lot of
      important things for granted, and I’ve been working on being the grown
      adult I want myself to be, by being intentional about my financial,
      career, education, and life after-35 goals. These seem to be taking shape,
      but I’m struggling with actually staying grounded to my faith goals. I am
      on the lukewarm side and I detest it.
      <br />
      <br />
      When I was 15 or 16, someone (who I still can’t remember no matter how
      hard I try) once told me, as you grow older, it becomes even harder than
      you think to create time to grow within your faith. And I never showed it,
      but deep down I scoffed—a gentle scoff, of course. Because when I was
      young, things like praying, casual conversing with God, and even jisting
      were effortless. It felt like an ordinary thing, and I had thought surely
      the relationship will grow and become better as time passed. But now, as I
      sit and think of how I long for those days, I know that person was right.
      <br />
      <br />I still try to create time for God, but there’s that spiritual
      connection that is missing, and I know why it’s missing because it was
      never about the conversations or the prayers; it was about the habits that
      kept Him close. I realized that in the past, there were no distracting
      habits, just struggling to get that perfect grade, having
      good times with friends, and that
      was it. Nothing ever crossed me to the level of losing my temper and
      crying out angrily. Point is,
      there are more things that are now proving hard to navigate and with more
      of this I feel farthest from His presence. To keep my sanity, I’ve taken
      some time away from their casual conversations (maybe I’m
      delusional but yes they were conversations) to help me deal with the
      constant trials.
      <br />
      <br />
      In the past, the gentle inner voice which I had come to recognize
      distinctively, would guide me through every emotion, every action. But
      somehow, that connection has slipped away. I pray to revive it, and I know
      the how-to, but it's not happening. I fully know it is in the habits I’ve
      picked up gradually that are pushing Him away. But those very habits are
      my coping mechanisms. So, the question is—how do I learn to let them go?
    </p>
  </div>
);

export const DX = () => (
  <div className="max-w-5xl mx-auto p-5 bg-[#f6f4ef] mt-5 mb-20 rounded-lg shadow-lg">
    <h1 className="text-3xl font-bold mb-4">My Learnings and Strategies in Building Better Developer Experience</h1>
    <p className="text-lg mt-4 text-gray-600">
      <p>
        When I started working in Developer Relations (DevRel), I didn't know
        much about Developer Experience (DX). In fact, I wasn't familiar with
        many DevRel terms. I learned most of it on the job, gradually
        understanding the different parts of DevRel: Developer Advocacy,
        Developer Marketing, Developer Success, and Developer Experience. At
        first, I struggled to cover all these areas to meet my job goals. DevRel
        works best with a team, and doing it all alone can be tough. But over
        time, I learned more and got better at handling my tasks. While building
        a community is important in DevRel, and it feels like I should have
        started by writing on it, I've been focusing more on Developer
        Experience lately and so I felt more compelled to write about it. If
        you're interested in learning about DX, then this blog is for you.
        <br />
        <br />
        <h1 className="text-2xl font-black">
          <strong>What is Developer Experience</strong>
        </h1>
        Developer Experience (DX) is about how developers interact with and feel
        about tools, APIs, or software. It's similar to User Experience (UX),
        but for developers. Good DX means the tools are easy to use and
        enjoyable, while bad DX can be frustrating and inefficient. People in
        DevRel teams work to make the developer experience positive and
        productive.
        <br />
        <br />
      </p>

      <h1 className="text-2xl font-black">
        <strong>How DevRels Improve Developer Experience</strong>
      </h1>

      <p>
        DevRel professionals bridge the gap between organizations and the
        developer community. While focusing on DX their responsibilities include
        but is not limited to updating documentation, creating educational
        materials and maintaining open-source projects (for companies with open
        source projects.)
        <br />
        <br /> Here’s a closer look at how they achieve this:
        <br />
        <br />
        <li>
          <strong>Keeping Documentation Up-to-Date:</strong> This means
          regularly updating API documentation to show new features and
          improvements. Good documentation helps developers understand how to
          use the tools and stay informed about changes.
          <br />
          <br />
        </li>{" "}
        <li>
          <strong>Creating Learning Materials:</strong> They create various
          learning materials, like guides, video tutorials, and sample projects.
          These resources help developers learn how to use tools more easily.
          For example, step-by-step guides quickly show developers how to use an
          API in their projects.
          <br />
          <br />
        </li>
        <li>
          <strong> Engaging with the Community:</strong> Engaging with the
          developer community is crucial and can happen through forums, social
          media, and events. Hosting webinars, joining discussions, and
          gathering feedback, help DevRel professionals to address developers'
          challenges and improve resources in turn increasing DX.
          <br />
          <br />
        </li>
        <h1 className="text-2xl font-black">
          Improving DX in Chimoney’s API Documentation and Resources
        </h1>
      </p>
      <p>
        Like I mentioned earlier, I have been focusing on Developer Experience
        of late and one of my most rewarding experiences has been improving DX
        for Chimoney's API Documentation and Community projects (I'll reference
        last year's efforts). <br />
        <br />
        Here’s how I'm doing it:
        <br />
        <br />
        <li>
          <strong>Enhancing Documentation:</strong>This has been a slow but
          rewarding process. I started by thoroughly reviewing our documentation
          and studying documentation from other products. I needed all the
          insights I could get to understand what makes documentation truly
          effective. Through the events we've been having in our community and
          others I attended to talk about our open-source project, I gradually
          gathered a lot of feedback. With these feedback, I worked on the first
          iteration of the documentation update which turned out well. I
          simplified the getting started guide, ensuring that all necessary
          information was easily accessible. I also updated the glossary,
          provided clear explanations for the endpoints, introduced a changelog,
          and identified and got bugs fixed in some endpoints. These
          improvements allowed developers to find the information they needed
          quickly and easily, leading to a smoother experience.
          <br />
          <br />
        </li>
        <li>
          <strong>Developing Learning Materials: </strong>
          This is what I am currently working on, and it honestly requires a lot
          of patience. I had started by creating a{" "}
          <a
            href="https://chimoney.io/api-use-cases/"
            className="text-blue-500 underline"
          >
            use case page{" "}
          </a>{" "}
          that includes step-by-step integration guides to help developers
          quickly understand how to incorporate Chimoney's APIs into their
          projects using real-world examples. Currently, I am developing
          integration guides for all of our endpoints and initially
          overestimated how quickly I could complete all the content, including
          written tutorials and videos. The goal is to create a variety of
          learning materials tailored to different learning styles. These
          resources help shorten the process of understanding how exactly the
          endpoints work and how to integrate them which all ties back to
          developer experience and shortening integration times.
          <br />
          <br />
        </li>
        <li>
          <strong>Active Community Engagement:</strong> For this I'll reference
          last year's participation in our open source project. Events, office
          hours or just setting up a specific channel for support as developers
          integrate is very crucial. What works for me is setting up office
          hours and having one on ones when I am available to debug or solve
          issues our community contributors are facing while integrating. These
          help me get first hand feedback to continuously improve our tools and
          documentation.
          <br />
          <br />
        </li>
        <li>
          <strong>Encouraging Contributions:</strong> Developers most times feel
          better contributing to projects that have a collaborative environment.
          For this we ensure to provide clear guidelines for contributing to our
          open-source projects. We also make sure to be responsive to pull
          requests and issues, creating a welcoming atmosphere for new
          contributors. This has helped to improve our projects and build a
          stronger, more supportive community because of the good developer
          experience in terms of support.
          <br />
          <br />
        </li>
      </p>

      <p>
        I am still learning on how to constantly improve DX in our documentation
        and resources and so this blog might not be as informative as I would
        like it to be but I will constantly update it as I work on more details
        and resources. Feel free to reach out and ask questions or give
        feedback.
        <br /> <br />
        Until next time!
        <br /> Phylis
      </p>
    </p>
  </div>
);
export const OF = () => (
  <div className="max-w-5xl mx-auto p-5 bg-[#f6f4ef] mt-5 mb-20 rounded-lg shadow-lg">
    <h1 className="text-3xl font-bold mb-4">Empowering Financial Communities through Open-Source Readiness</h1>
    <p className="text-lg mt-4 text-gray-600">
      <p>
As someone involved in community building and developer relations, I’ve seen the amazing impact of 
open source on financial ecosystems. While I never got to present my talk at the  <a
            href="https://events.linuxfoundation.org/open-source-finance-forum-london/"
            className="text-blue-500 underline"
          >
            Open Source Finance Forum,{" "}
          </a> 
I still wanted to share my thoughts on how open-source readiness can empower financial communities, 
gathering from my experience at Chimoney.<br/><br/>
<strong>Understanding Open Source Readiness</strong><br/>
When I talk about open-source readiness, I’m referring to more than just the ability to use open-source 
software. It’s about creating a mindset and a culture that embraces collaboration, transparency, and 
innovation. Open source is a philosophy that, when properly understood and adopted, can create significant 
potential for any community—especially in the finance sector.
At Chimoney, while we haven't built major features from the ground up using open source, we actively 
leverage open-source projects and tools developed by the community. For instance, we leverage SDKs and tools 
developed by open-source contributors, which enable us to streamline processes like rewarding contributors 
directly through Chimoney wallets on GitHub.<br/><br/>
<strong>How Open Source Transforms Financial Ecosystems</strong><br/>
The traditional financial industry has often been closed off, with proprietary systems and limited 
collaboration. But I’m seeing firsthand how open-source projects are changing that landscape. By opening up 
development and inviting collaboration, these projects are creating more inclusive, flexible, and innovative 
financial systems.
At Chimoney, we’ve used the power of open source to improve our platform’s capabilities. In particular, our 
integration with Interledger (a global open protocol for payments) has allowed us to contribute to and benefit 
from a network of innovators working towards making financial transactions more accessible whilst staying true to our mission of financial inclusion.
<br/><br/><strong>Open Source in Driving Financial Inclusion</strong><br/>
I honestly believe that open-source technologies play a part in achieving financial inclusion. In many parts of the world, access to financial services is still limited, and traditional systems often fail to reach the people who need them most. But open source can change that by providing the tools and platforms needed to build inclusive financial systems.
At Chimoney, our focus on open-source readiness has enabled us to create solutions that cater to a wide range 
of users. We're currently improving features like our multi-currency wallets and cross-border payment services 
by integrating with Interledger’s open-source Rafiki API, making these services accessible to everyone, 
regardless of location or economic status. By leveraging open-source technologies, we can ensure that our services are not only innovative but also inclusive and equitable.
<br/><br/><strong>Practical Ways for Embracing Open Source Readiness</strong><br/>
Here are a few strategies that I think can help others embrace open-source readiness in their 
financial communities:<br/><br/>
<strong>1. Education First:</strong> It’s important to educate your team and community about what open source 
means and how it can benefit them. Knowing the core ideas behind open source is key to success.<br/><br/>
<strong>2. Active Collaboration:</strong> Engage with existing open-source communities and contribute to 
projects that align with your goals. This collaborative spirit is at the heart of what makes open source so 
powerful.<br/><br/>
<strong>3. Invest in the Right Infrastructure:</strong> Make sure your technical infrastructure is capable of 
supporting open-source tools. This not only includes the software stack but also creating an environment that 
encourages continuous learning and improvement.<br/><br/>
<strong>4. Encourage Creativity:</strong> Allow your team the freedom to experiment with open-source tools and
 ideas. Innovation often comes from unexpected places, so it’s important to create an environment where 
 creativity is encouraged.<br/><br/>
<strong>5. Focus on Inclusion:</strong> Always keep the needs of underrepresented and underserved communities 
in mind when developing open-source solutions. Financial inclusion should be a priority in any open-source 
project in the financial sector.<br/><br/>
I hope this blog inspires you to consider how you can include open-source readiness in your own work—either as a developer, community manager, or someone involved in the financial sector in any capacity.
<br/><br/>
Until next time!


</p>
      </p>
      </div>
);
